import { Container } from "react-bootstrap";
import styles from "../styles/mcpack.module.css";
import McpackListView from "../components/McpackListView";
import TextureSelectionDemo from "../components/TextureSelectionDemo";

const McpackPage = () => {
    const logo = "../../minecraft-logo.png";

    return (
        <div>
            <Container className={styles.mcpackPage}>
                <img src={logo} className={styles.logo} alt="logo" />
                <p>
                    *** CousinCraft Texture Pack Website ***
                </p>
            </Container>

            <Container className={styles.mcpackPage}>
                <McpackListView />
            </Container>
        </div>
    );
}

export default McpackPage;
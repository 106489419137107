import { Key, useEffect, useState } from 'react';
import TextureLineItem from './Mcpack';
import { Mcpack } from "../models/mcpackModel";
import * as NotesApi from "../network/notes_api";
import styles from "../styles/NotesPage.module.css";
import { Col, Row, Spinner } from 'react-bootstrap';
import { TextureData } from '../models/mcpackModel';

const McpackListView = () => {

    const [mcpacks, setMcpacks] = useState<Mcpack[]>([]);
    const [mcpacksLoading, setmcpacksLoading] = useState(true);
    const [showMcpacksLoadingError, setShowMcpacksLoadingError] = useState(false);

    useEffect(() => {
        async function loadMcpacks() {
            try {
                setShowMcpacksLoadingError(false);
                setmcpacksLoading(true);
                const mcpacks = await NotesApi.getMcpacks();
                setMcpacks(mcpacks);
                console.log(mcpacks);
            } catch (error) {
                console.error(error);
                setShowMcpacksLoadingError(true);
            } finally {
                setmcpacksLoading(false);
            }
        }
        loadMcpacks();
    }, []);

    let mcpacksGrid;

    if (mcpacks.length > 0) {
        const textureData = mcpacks[0].resources?.textures?.texture_data || [];
        const mcpacksGrid = Array.from(textureData.entries()).map(([key, texture]) => {
          if (texture.name !== undefined) {
            return (
              <Col key={texture.name}>
                <TextureLineItem
                  mcpack={mcpacks[0]}
                  index={key}
                  className={styles.note}
                />
              </Col>
            );
          } else {
            return null;
          }
        });
      
        return (
          <Row xs={1} md={1} xl={1} className={`g-4 ${styles.notesGrid}`}>
            {mcpacksGrid}
          </Row>
        );
      }

    return (
        <>
            {mcpacksLoading && <Spinner animation='border' variant='primary' />}
            {showMcpacksLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!mcpacksLoading && !showMcpacksLoadingError &&
                <>
                    {mcpacks.length > 0
                        ? mcpacksGrid
                        : <p>There are no texture packs to display...</p>
                    }
                </>
            }
        </>
    );
}

export default McpackListView;
import { Container } from "react-bootstrap";
import styles from "../styles/TexturePackCreator.module.css";
/*import McpackListView from "../components/McpackListView";*/
import TextureSelectionDemo from "../components/TextureSelectionDemo";

const TexturePackCreator = () => {
    const logo = "../../minecraft-logo.png";

    return (
        <div>
            <Container className={styles.TexturePackCreatorPage}>
                <img src={logo} className={styles.logo} alt="logo" />
                <p>
                    * CousinCraft Texture Pack Website *
                </p>
            </Container>
            <Container className={styles.TexturePackCreatorPage}>
	            <TextureSelectionDemo />
            </Container>
            {/* <Container className={styles.mcpackPage}>
                <McpackListView />
            </Container> */}
        </div>
    );
}

export default TexturePackCreator;
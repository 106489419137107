import styles from "../styles/mcpack.module.css";
import styleUtils from "../styles/utils.module.css";
import { Card } from "react-bootstrap";
import { Mcpack } from "../models/mcpackModel";

interface TextureDataProps {
	mcpack: Mcpack,
	index: number,
	className?: string,
}

const TextureLineItem = ({ mcpack, index, className }: TextureDataProps) => {
	try {
		if (!mcpack.resources || !mcpack.resources.textures || !mcpack.resources.textures.texture_data) {
			return null;
		} else {
			const textureData = mcpack.resources.textures.texture_data[index];

			if (!textureData) {
				return null;
			}

			return (
				<Card className={`${styles.mcpackCard} ${className}`}>
					<Card.Body className={styles.cardBody}>
						<Card.Title className={styleUtils.flexCenter}>
							{textureData.name}
						</Card.Title>
						<Card.Text className={styles.cardText}>
							{textureData.textures?.map((texture) => (
								<span key={index}>{mcpack.path + texture + ".png"}
									<br /><img src={mcpack.path + texture + ".png"} alt="" width="64" height="64" />
								</span>
							))}
						</Card.Text>
					</Card.Body>
				</Card>
			);
		}
	} catch (error) {
		console.error(error);
	}
	return null;
}

export default TextureLineItem;

import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import { Note } from "../models/notesModel";
import { User } from "../models/usersModel";
import { Mcpack } from "../models/mcpackModel";

// set to true if you want to use the local backend server or false if you want to use the cloud deployed backend server
// currently not working with the cloud deployed backend server (when set to false) due to cors issues (cross site scripting securityy)
const localBackendOn = true;
const serverURL = localBackendOn ? "" : "https://CousinCraft.sbombar.com";

async function fetchData(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init);

    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error;

        if (response.status === 401) {
            throw new UnauthorizedError(errorMessage);
        } else if (response.status === 409) {
            throw new ConflictError(errorMessage);
        } else {
            throw Error("Request failed with status: " + response.status + " message: " + errorMessage);
        }
    }
}

export async function getMcpacks(): Promise<Mcpack[]> {
    const response = await fetchData(serverURL + "/api/mcpacks",
        {
            method: "GET",
            // mode: 'cors',
            // headers: {
            //     "Access-Control-Allow-Origin":"*",
            // }
        });

    return response.json();  
}

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData(serverURL + "/api/users",
        {
            method: "GET",
            // mode: 'cors',
            // headers: {
            //     "Access-Control-Allow-Origin":"*",
            // }
        });

    return response.json();
}

export interface SignUpCredentials {
    username: string,
    email: string,
    password: string,
}

export async function signUp(credentials: SignUpCredentials): Promise<User> {
    const response = await fetchData(serverURL + "/api/users/signup",
        {
            method: "POST",
            // mode: 'cors',
            headers: {
                // "Access-Control-Allow-Origin":"*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });

    return response.json();
}

export interface LoginCredentials {
    username: string,
    password: string,
}

export async function login(credentials: LoginCredentials): Promise<User> {
    const response = await fetchData(serverURL + "/api/users/login",
        {
            method: "POST",
            // mode: 'cors',
            headers: {
                // "Access-Control-Allow-Origin":"*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });

    return response.json();
}

export async function logout() {
    await fetchData("/api/users/logout",
        {
            method: "POST",
            // mode: 'cors',
            // headers: {
            //     "Access-Control-Allow-Origin":"*",
            // }
        });
}

export async function fetchNotes(): Promise<Note[]> {
    const response = await fetchData(serverURL + "/api/notes",
        {
            method: "GET",
            // mode: 'cors',
            // headers: {
            //     "Access-Control-Allow-Origin":"*",
            // }
        });

    return response.json();
}

export interface NoteInput {
    title: string,
    text?: string,
}

export async function createNote(note: NoteInput): Promise<Note> {
    const response = await fetchData(serverURL + "/api/notes",
        {
            method: "POST",
            // mode: 'cors',
            headers: {
                // "Access-Control-Allow-Origin":"*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(note),
        });

    return response.json();
}

export async function updateNote(noteId: string, note: NoteInput): Promise<Note> {
    const response = await fetchData(serverURL + "/api/notes/" + noteId,
        {
            method: "PATCH",
            // mode: 'cors',
            headers: {
                // "Access-Control-Allow-Origin":"*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(note),
            // log something here ??
        });

    return response.json();
}

export async function deleteNote(noteId: string) {
    await fetchData(serverURL + "/api/notes/" + noteId,
        {
            method: "DELETE",
            // mode: 'cors',
            // headers: {
            //     "Access-Control-Allow-Origin":"*",
            // }
        });
}
import { useEffect, useState } from 'react';
import LoginDialog from './components/LoginDialog';
import NavBar from './components/NavBar';
import SignUpDialog from './components/SignUpDialog';
import { User } from './models/usersModel';
import * as NotesApi from "./network/notes_api";
import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotesPage from './pages/NotesPage';
import NotFoundPage from './pages/NotFoundPage';
import styles from './styles/App.module.css';
import McpackPage from './pages/McpackPage';
import TexturePackCreator from './pages/TexturePackCreator';

function App() {

	const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

	const [showSignUpDialog, setShowSignUpDialog] = useState(false);
	const [showLoginDialog, setShowLoginDialog] = useState(false);

	useEffect(() => {
		async function fetchLoggedInUser() {
			try {
				const user = await NotesApi.getLoggedInUser();
				setLoggedInUser(user);
			} catch (error) {
				console.error(error);
			}
		}
		fetchLoggedInUser();
	}, []);

	return (
		<BrowserRouter>
			<div>
				<NavBar
					loggedInUser={loggedInUser}
					onLoginClicked={() => setShowLoginDialog(true)}
					onSignUpClicked={() => setShowSignUpDialog(true)}
					onLogoutSuccessful={() => setLoggedInUser(null)}
				/>

				<Container className={styles.pageContainer}>
					<Routes>
						<Route
							path='/'
							element={<TexturePackCreator />}
						/>
						<Route
							path='/tpTest'
							element={<McpackPage />}
						/>
						<Route
							path='/notes'
							element={<NotesPage loggedInUser={loggedInUser} />}
						/>

						<Route
							path='/*'
							element={<NotFoundPage />}
						/>
					</Routes>
				</Container>

				{showSignUpDialog &&
					<SignUpDialog
						onDismiss={() => setShowSignUpDialog(false)}
						onSignUpSuccessful={(user) => {
							setLoggedInUser(user);
							setShowSignUpDialog(false);
						}}
					/>
				}
				{showLoginDialog &&
					<LoginDialog
						onDismiss={() => setShowLoginDialog(false)}
						onLoginSuccessful={(user) => {
							setLoggedInUser(user);
							setShowLoginDialog(false);
						}}
					/>
				}
			</div>
		</BrowserRouter>
	);
}

export default App;

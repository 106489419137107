import { useState } from "react";
import { Card } from "react-bootstrap";
import styles from "../styles/TexturePackCreator.module.css";

const TextureSelectionDemo = () => {
  const texturePacks = [
    {
      name: "Default",
      image: "/mcpacks/bedrock-samples-1.19.70.2/resource_pack/textures/blocks/dirt.png",
    },
    {
      name: "Faithful 32x32",
      image: "/mcpacks/Faithful32x-1.19/textures/blocks/dirt.png",
    },
    {
      name: "Mosiac",
      image: "/mcpacks/Mosiac/textures/blocks/dirt.png",
    },
    {
      name: "Madoku-Craft-0.4.6",
      image: "/mcpacks/Madoku-Craft-0.4.6/textures/blocks/dirt.png",
    },
  ];

  const [selectedTexture, setSelectedTexture] = useState(texturePacks[0]);

  const handleTextureSelect = (texturePack: typeof texturePacks[0]) => {
    setSelectedTexture(texturePack);
  };

  return (
    <Card>
      <Card.Body className={styles.cardBody}>
        <Card.Title className={styles.cardTitle}>
          Dirt
          <br />
          <span className={styles.subheading}>(dirt.png)</span>
        </Card.Title>
        <div className="d-flex justify-content-between flex-wrap">
          {texturePacks.map((texturePack) => (
            <Card
              key={texturePack.name}
              style={{ width: "10rem", margin: "0.5rem" }}
              className={`${styles.textureCard} ${texturePack.name === selectedTexture.name ? styles.selected : ""}`}
              onClick={() => handleTextureSelect(texturePack)}
            >

              <Card.Img
                variant="top"
                src={texturePack.image}
                style={{ imageRendering: "pixelated" }}
              />
              <Card.Body>
                <Card.Title>{texturePack.name}</Card.Title>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TextureSelectionDemo;
